var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.membershipStartDateField)?_c('base-date-picker',{attrs:{"label":_vm.membershipStartDateField.customLabel
          ? _vm.membershipStartDateField.customLabel
          : _vm.membershipStartDateField.label,"label-cols-sm":"4","asterix":_vm.membershipStartDateField.required,"max":_vm.maxStartDate,"description":_vm.membershipStartDateField.description},model:{value:(_vm.membershipStartDate),callback:function ($$v) {_vm.membershipStartDate=$$v},expression:"membershipStartDate"}}):_vm._e(),(_vm.planField)?_c('b-form-group',{attrs:{"label-cols-sm":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.planFieldLabel)+" "),(_vm.planField.required)?_c('span',[_vm._v("* ")]):_vm._e(),_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.planFieldDescription),expression:"planFieldDescription",modifiers:{"hover":true,"top":true}}]},[_c('b-icon-question-circle',{attrs:{"variant":"info"}})],1)]},proxy:true}],null,false,57281408)},[_c('validation-provider',{attrs:{"rules":{ required: _vm.planField.required },"name":_vm.planFieldLabel},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('div',{staticClass:"registration-form-radio"},[(
              _vm.isBirthDateFilled &&
              !_vm.membershipStartDateField &&
              _vm.availablePlans.length === 0
            )?_c('span',[_vm._v(_vm._s(_vm.$t("no_plans_found_for_age")))]):_vm._e(),(
              _vm.isBirthDateFilled &&
              _vm.membershipStartDateField &&
              _vm.availablePlans.length === 0
            )?_c('span',[_vm._v(_vm._s(_vm.$t("no_plans_found_for_age_and_date")))]):_vm._l((_vm.availablePlans),function(option){return _c('b-form-radio',{key:option.id,staticClass:"mb-2",attrs:{"name":"plan-selection","value":option.id,"disabled":!_vm.isBirthDateFilled},on:{"input":_vm.handlePlanSelection},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},[_vm._v(_vm._s(_vm.getPlanDisplayLabel(option)))])}),_vm._l((errors),function(error){return _c('p',{key:error,staticClass:"text-danger mb-0"},[_vm._v(" "+_vm._s(error)+" ")])})],2)]}}],null,false,1515273487)})],1):_vm._e(),(_vm.organisationField)?_c('b-form-group',{attrs:{"label-cols-sm":"4"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.organisationField.customLabel || _vm.organisationField.label)+" "),(_vm.organisationField.required)?_c('span',[_vm._v("* ")]):_vm._e(),(_vm.organisationField.description)?_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.organisationField.description),expression:"organisationField.description",modifiers:{"hover":true,"top":true}}]},[_c('b-icon-question-circle',{attrs:{"variant":"info"}})],1):_vm._e()]},proxy:true}],null,false,2395242402)},[_c('validation-provider',{attrs:{"rules":{ required: _vm.organisationField.required },"name":_vm.organisationField.customLabel || _vm.organisationField.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('multiselect',{attrs:{"options":_vm.organisationsList,"placeholder":_vm.$t('select_option'),"label":"name","track-by":"id"},on:{"input":_vm.handleOrganisationIdInput},model:{value:(_vm.multiselectValue),callback:function ($$v) {_vm.multiselectValue=$$v},expression:"multiselectValue"}}),_vm._l((errors),function(error){return _c('p',{key:error,staticClass:"text-danger mb-0"},[_vm._v(" "+_vm._s(error)+" ")])})]}}],null,false,1508320274)})],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.federationMembershipIdentifierField)?_c('form-field-input',{attrs:{"field":_vm.federationMembershipIdentifierField,"formConfig":_vm.formConfig,"input":_vm.input},on:{"input":_vm.handleFieldInput}}):_vm._e(),(_vm.voucherField)?_c('b-overlay',{attrs:{"show":_vm.voucherLoading}},[(!_vm.voucherData)?_c('form-field-input',{attrs:{"field":_vm.voucherField,"formConfig":_vm.formConfig,"input":_vm.input},on:{"input":_vm.handleFieldInput}}):_vm._e(),(_vm.voucherData)?_c('base-text-row',{attrs:{"label":_vm.$t('discount'),"label-cols-sm":"4","value":_vm.voucherDiscount}}):_vm._e()],1):_vm._e(),(_vm.disciplineField)?_c('form-field-input',{attrs:{"field":_vm.disciplineField,"formConfig":_vm.formConfig,"input":_vm.input},on:{"input":_vm.handleFieldInput}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }