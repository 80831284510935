<template>
  <div class="container registration-form" v-if="loaded">
    <link
      rel="stylesheet"
      href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css"
    />

    <h1>{{ formConfig.title }}</h1>

    <div class="mb-2 mt-2" v-html="formConfig.description"></div>

    <b-alert :show="!!errorMsg" variant="danger">{{ errorMsg }}</b-alert>

    <validation-observer v-slot="{ handleSubmit }" v-show="state === 'Form'">
      <b-form @submit.prevent="handleSubmit(() => (state = 'Overview'))">
        <b-row
          v-for="formModule in formConfig.fieldsConfiguration"
          :key="formModule.name"
          class="mt-2"
        >
          <b-col>
            <div v-html="formModule.description"></div>

            <address-module
              v-if="formModule.name === 'Address'"
              :input="input"
              :form-config="formConfig"
              :form-module="formModule"
            />

            <financial-details-module
              v-else-if="formModule.name === 'FinancialDetails'"
              :input="input"
              :form-config="formConfig"
              :form-module="formModule"
            />

            <membership-module
              v-else-if="formModule.name === 'MembershipDetails'"
              :input="input"
              :form-config="formConfig"
              :form-module="formModule"
              @voucher-loaded="
                (data) => {
                  voucherData = data;
                }
              "
            />

            <services-module
              v-else-if="formModule.name === 'Services'"
              :input="input"
              :form-config="formConfig"
              :form-module="formModule"
            />

            <template v-else-if="!formModule.isCustom">
              <b-row>
                <b-col cols="12" md="6">
                  <template
                    v-for="field in formModule.fields.filter(
                      (x, i, a) => i < Math.ceil(a.length / 2)
                    )"
                  >
                    <form-field-input
                      v-if="field.isVisible"
                      :key="field.key"
                      :field="field"
                      :formConfig="formConfig"
                      :input="input"
                      @input="handleFieldInput"
                    />
                  </template>
                </b-col>
                <b-col cols="12" md="6">
                  <template
                    v-for="field in formModule.fields.filter(
                      (x, i, a) => i >= Math.ceil(a.length / 2)
                    )"
                  >
                    <form-field-input
                      v-if="field.isVisible"
                      :key="field.key"
                      :field="field"
                      :formConfig="formConfig"
                      :input="input"
                      @input="handleFieldInput"
                    />
                  </template>
                </b-col>
              </b-row>
            </template>

            <parent-info-module
              v-else-if="
                formModule.isCustom && formModule.name === 'ParentInfo'
              "
              v-model="input.ParentInfos"
            />

            <files-module
              v-else-if="formModule.isCustom && formModule.name === 'Files'"
              v-model="input.Files"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center mb-5">
            <hr />
            <b-btn type="submit" variant="primary" class="continue-button">{{
              $t("continue")
            }}</b-btn>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal
      id="personExistsWarningModal"
      static
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div
        v-html="formConfig.textToShowWhenPersonExists || $t('person_exists')"
      />
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel">
          {{ $t("ok") }}
        </b-button>
      </template>
    </b-modal>

    <confirmation-form
      :voucherData="voucherData"
      :formInput="input"
      :formConfig="formConfig"
      v-if="state === 'Overview'"
      @cancel="state = 'Form'"
    />
  </div>
</template>

<script>
import axios from "axios";
import { appSettings } from "@/appSettings";
import { localize } from "vee-validate";
import moduleCommonMixin from "./moduleCommonMixin";
import { extend } from "vee-validate";

export default {
  mixins: [moduleCommonMixin],
  data() {
    return {
      state: "Form",
      formConfig: {},
      input: {},
      loaded: false,
      errorMsg: null,
      debounceTimer: null,
      voucherData: null,
    };
  },
  async created() {
    await this.loadFormConfig();
    let me = this;
    let passwordField = this.allFields.find((x) => x.key == "Password");
    let confirmPasswordField = this.allFields.find(
      (x) => x.key == "ConfirmPassword"
    );

    if (passwordField && confirmPasswordField) {
      let passwordFieldLabel = passwordField.customLabel ?? passwordField.label;
      let confirmPasswordFieldLabel =
        confirmPasswordField.customLabel ?? confirmPasswordField.label;
      extend("confirmPassword", {
        validate(value) {
          return value === me.input.Password;
        },
        message: me.$t(`rules.must_be_the_same`, {
          field1: confirmPasswordFieldLabel,
          field2: passwordFieldLabel,
        }),
      });
    }
  },
  computed: {
    allFields() {
      return this.formConfig.fieldsConfiguration
        .filter((el) => el.fields)
        .map((el) => el.fields)
        .flat();
    },
  },
  watch: {
    "input.FirstName"() {
      this.validateExistingPerson();
    },
    "input.LastName"() {
      this.validateExistingPerson();
    },
    "input.BirthDate"() {
      this.validateExistingPerson();
    },
    "input.Gender"() {
      this.validateExistingPerson();
    },
  },
  methods: {
    async validateExistingPerson() {
      var firstName = this.input.FirstName;
      var lastName = this.input.LastName;
      var birthDate = this.input.BirthDate;
      var gender = this.input.Gender;

      if (firstName && lastName && birthDate && (gender || gender == 0)) {
        var model = {
          firstName,
          lastName,
          birthDate,
          gender,
          organisationId: this.formConfig.tenantId,
        };

        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          var existingPersonCheckUrl = `${appSettings.FoysPubRegistrationsUrl}/members/existing-person`;

          axios
            .get(existingPersonCheckUrl, {
              params: model,
            })
            .then(() => {
              this.$bvModal.show("personExistsWarningModal");
            });
        }, 500);
      }
    },
    getUrl() {
      let registrationFormEntry = document.querySelectorAll(
        "registration-form-entry"
      )[0];
      if (typeof registrationFormEntry === "undefined") {
        throw new Error('"registration-form-entry" html element is not found');
      }
      let configId = registrationFormEntry.getAttribute("configuration");
      if (!configId) {
        throw new Error(
          '"registration-form-entry" configuration property is not set'
        );
      }

      return `${appSettings.FoysPubRegistrationsUrl}/registration-forms/${configId}`;
    },
    async loadFormConfig() {
      await axios
        .get(this.getUrl())
        .then((response) => {
          this.formConfig = response.data;
          this.$i18n.locale = this.formConfig.locale;
          localize(this.formConfig.locale.substring(0, 2));

          for (var formModule of this.formConfig.fieldsConfiguration.filter(
            (x) => !x.isCustom
          )) {
            formModule.fields.forEach((x) => this.$set(x, "isVisible", true));
          }

          this.loaded = true;
        })
        .catch((error) => {
          this.errorMsg = error;
          console.log(error);
        });
    },
  },
};
</script>