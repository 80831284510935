var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showSuccess)?_c('b-alert',{attrs:{"show":true,"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t("success"))+" ")]):_c('b-overlay',{attrs:{"show":_vm.saving}},[_c('b-alert',{attrs:{"show":!!_vm.errorMsg,"variant":"danger"}},[_vm._v(_vm._s(_vm.errorMsg))]),_vm._l((_vm.formConfig.fieldsConfiguration),function(formModule){return _c('b-row',{key:formModule.name,staticClass:"mt-4"},[_c('b-col',[_c('div',{domProps:{"innerHTML":_vm._s(formModule.description)}}),(!formModule.isCustom)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},_vm._l((formModule.fields
                  .filter(
                    function (x) { return x.isVisible && !_vm.isHiddenFromConfirmationForm(x); }
                  )
                  .filter(function (x, i, a) { return i < Math.ceil(a.length / 2); })),function(field){return _c('base-text-row',{key:field.key,attrs:{"label":_vm.getLabel(field),"value":_vm.getFieldValue(field)}})}),1),_c('b-col',{attrs:{"cols":"12","md":"6"}},_vm._l((formModule.fields
                  .filter(
                    function (x) { return x.isVisible && !_vm.isHiddenFromConfirmationForm(x); }
                  )
                  .filter(function (x, i, a) { return i >= Math.ceil(a.length / 2); })),function(field){return _c('base-text-row',{key:field.key,attrs:{"label":_vm.getLabel(field),"value":_vm.getFieldValue(field)}})}),1)],1)]:_vm._e(),(formModule.isCustom && formModule.name === 'ParentInfo')?_c('div',[_c('b-table',{staticClass:"mt-2",attrs:{"fields":_vm.parentInfoModuleFields,"items":_vm.formInput.ParentInfos,"borderless":""}})],1):_vm._e(),(formModule.isCustom && formModule.name === 'Files')?_c('div',_vm._l((_vm.formInput.Files),function(file){return _c('p',{key:file.fileName},[_vm._v(" "+_vm._s(file.fileName)+" ")])}),0):_vm._e()],2)],1)}),(_vm.formConfig.allowDirectPayment)?_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.voucherDiscount)?_c('base-text-row',{attrs:{"label":_vm.$t('discount'),"value":("€ " + (_vm.voucherDiscount.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          })))}}):_vm._e(),_c('base-text-row',{attrs:{"label":_vm.$t('total_to_pay'),"value":("€ " + _vm.totalPrice)}})],1)],1):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [(_vm.isAgreementEnabled)?_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('p',[_c('validation-provider',{attrs:{"rules":{ required: true },"name":_vm.$t('conditions_checkbox')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"validatable":""},model:{value:(_vm.agreement),callback:function ($$v) {_vm.agreement=$$v},expression:"agreement"}},[_vm._v(" "+_vm._s(_vm.$t("agree_with_the"))+" "),(_vm.formConfig.termsAndConditionsUrl)?_c('a',{attrs:{"target":"_blank","href":_vm.formConfig.termsAndConditionsUrl}},[_vm._v(_vm._s(_vm.$t("terms_and_conditions")))]):(_vm.formConfig.termsAndConditionsText)?[_vm._v(_vm._s(_vm.$t("terms_and_conditions")))]:_vm._e(),(
                    (_vm.formConfig.termsAndConditionsUrl ||
                      _vm.formConfig.termsAndConditionsText) &&
                    _vm.formConfig.privacyPolicyUrl
                  )?[_vm._v(" "+_vm._s(_vm.$t("agree_and"))+" ")]:_vm._e(),(_vm.formConfig.privacyPolicyUrl)?_c('a',{attrs:{"target":"_blank","href":_vm.formConfig.privacyPolicyUrl}},[_vm._v(_vm._s(_vm.$t("privacy_policy")))]):_vm._e()],2),_vm._l((errors),function(error){return _c('p',{key:error,staticClass:"text-danger mb-0"},[_vm._v(" "+_vm._s(error)+" ")])})]}}],null,true)})],1),(_vm.formConfig.termsAndConditionsText)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.formConfig.termsAndConditionsText)}}):_vm._e()])],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"text-center mb-5"},[_c('hr'),_c('b-btn',{attrs:{"variant":"secondary","disabled":_vm.saving},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("go_back"))+" ")]),_c('b-btn',{staticClass:"mr-2 ml-2 confirm-button",attrs:{"disabled":_vm.saving,"variant":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")])],1)],1)]}}])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }