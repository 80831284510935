export const planMixin = {
    methods: {
        getPlanDisplayLabel(plan) {
            var name = plan.name;
            var price = plan.price.toLocaleString(undefined, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });

            var registrationFee = plan.registrationFee
                ? plan.registrationFee.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })
                : null;


            var remittanceFee = plan.remittanceFee
                ? plan.remittanceFee.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })
                : null;


            var pricePart =
                `(€ ${price}` +
                (registrationFee
                    ? ` + € ${registrationFee} ${this.$t("registration_fee")}`
                    : "") +
                (remittanceFee
                    ? ` + € ${remittanceFee} ${plan.remittanceDescription || this.$t("remittance_fee")}`
                    : "") +
                ")";

            return `${name} ${pricePart}`;
        },
    }
}