<template>
  <div>
    <b-alert v-if="showSuccess" :show="true" variant="success">
      {{ $t("success") }}
    </b-alert>
    <b-overlay v-else :show="saving">
      <b-alert :show="!!errorMsg" variant="danger">{{ errorMsg }}</b-alert>

      <b-row
        v-for="formModule in formConfig.fieldsConfiguration"
        :key="formModule.name"
        class="mt-4"
      >
        <b-col>
          <div v-html="formModule.description"></div>

          <template v-if="!formModule.isCustom">
            <b-row>
              <b-col cols="12" md="6">
                <base-text-row
                  v-for="field in formModule.fields
                    .filter(
                      (x) => x.isVisible && !isHiddenFromConfirmationForm(x)
                    )
                    .filter((x, i, a) => i < Math.ceil(a.length / 2))"
                  :key="field.key"
                  :label="getLabel(field)"
                  :value="getFieldValue(field)"
                />
              </b-col>
              <b-col cols="12" md="6">
                <base-text-row
                  v-for="field in formModule.fields
                    .filter(
                      (x) => x.isVisible && !isHiddenFromConfirmationForm(x)
                    )
                    .filter((x, i, a) => i >= Math.ceil(a.length / 2))"
                  :key="field.key"
                  :label="getLabel(field)"
                  :value="getFieldValue(field)"
                />
              </b-col>
            </b-row>
          </template>

          <div v-if="formModule.isCustom && formModule.name === 'ParentInfo'">
            <b-table
              class="mt-2"
              :fields="parentInfoModuleFields"
              :items="formInput.ParentInfos"
              borderless
            >
            </b-table>
          </div>

          <div v-if="formModule.isCustom && formModule.name === 'Files'">
            <p v-for="file in formInput.Files" :key="file.fileName">
              {{ file.fileName }}
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="formConfig.allowDirectPayment" class="mt-4">
        <b-col cols="12" md="6">
          <base-text-row
            v-if="voucherDiscount"
            :label="$t('discount')"
            :value="`€ ${voucherDiscount.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}`"
          />
          <base-text-row
            :label="$t('total_to_pay')"
            :value="`€ ${totalPrice}`"
          />
        </b-col>
      </b-row>

      <validation-observer v-slot="{ handleSubmit }">
        <b-row v-if="isAgreementEnabled" class="mt-4">
          <b-col>
            <p>
              <validation-provider
                :rules="{ required: true }"
                v-slot="{ errors }"
                :name="$t('conditions_checkbox')"
              >
                <b-form-checkbox v-model="agreement" validatable>
                  {{ $t("agree_with_the") }}
                  <a
                    v-if="formConfig.termsAndConditionsUrl"
                    target="_blank"
                    :href="formConfig.termsAndConditionsUrl"
                    >{{ $t("terms_and_conditions") }}</a
                  >
                  <template v-else-if="formConfig.termsAndConditionsText">{{
                    $t("terms_and_conditions")
                  }}</template>
                  <template
                    v-if="
                      (formConfig.termsAndConditionsUrl ||
                        formConfig.termsAndConditionsText) &&
                      formConfig.privacyPolicyUrl
                    "
                  >
                    {{ $t("agree_and") }}
                  </template>
                  <a
                    v-if="formConfig.privacyPolicyUrl"
                    target="_blank"
                    :href="formConfig.privacyPolicyUrl"
                    >{{ $t("privacy_policy") }}</a
                  >
                </b-form-checkbox>
                <p
                  v-for="error in errors"
                  :key="error"
                  class="text-danger mb-0"
                >
                  {{ error }}
                </p>
              </validation-provider>
            </p>

            <p
              v-if="formConfig.termsAndConditionsText"
              v-html="formConfig.termsAndConditionsText"
            ></p>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center mb-5">
            <hr />
            <b-btn @click.stop.prevent="$emit('cancel')" variant="secondary" :disabled="saving">
              {{ $t("go_back") }}
            </b-btn>
            <b-btn
              @click.stop.prevent="handleSubmit(submit)"
              :disabled="saving"
              class="mr-2 ml-2 confirm-button"
              variant="primary"
            >
              {{ $t("confirm") }}
            </b-btn>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { appSettings } from "@/appSettings";
import { shortDateFilter, planMixin } from "@/shared";
import { countryHelper } from "../countryHelper.js";

export default {
  mixins: [countryHelper, planMixin],
  data() {
    return {
      errorMsg: null,
      saving: false,
      showSuccess: false,
      agreement: false,
    };
  },
  props: {
    formInput: null,
    formConfig: null,
    voucherData: null,
  },
  computed: {
    isAgreementEnabled() {
      return !!(
        this.formConfig.termsAndConditionsText ||
        this.formConfig.termsAndConditionsUrl ||
        this.formConfig.privacyPolicyUrl
      );
    },
    registrationFormUrl() {
      return `${appSettings.FoysPubRegistrationsUrl}/registration-forms/${this.formConfig.id}`;
    },
    parentInfoModuleFields() {
      return [
        { label: this.$t("name"), key: "parentName" },
        {
          label: this.$t("relationship"),
          key: "relationship",
        },
        { label: this.$t("email"), key: "email" },
        { label: this.$t("phone"), key: "phone" },
        {
          label: this.$t("wants_to_receive_emails"),
          key: "receiveEmails",
        },
      ];
    },
    servicesField() {
      var servicesModule = this.formConfig.fieldsConfiguration.find(
        (x) => x.name === "Services"
      );

      if (!servicesModule) return null;

      return servicesModule.fields.find((x) => x.key == "ServiceIds");
    },
    selectedPlan() {
      var planFieldKey = "PlanAssignment.planId";
      var membershipModule = this.formConfig.fieldsConfiguration.find(
        (x) => x.name === "MembershipDetails"
      );

      if (!membershipModule) return null;

      var planField = membershipModule.fields.find(
        (x) => x.key == planFieldKey
      );

      var selectedPlanId = _.get(this.formInput, planFieldKey);

      if (!planField || !selectedPlanId) return null;

      var selectedPlan = planField.options.find(
        (el) => el.id === selectedPlanId
      );

      return selectedPlan;
    },
    voucherDiscount() {
      if (!this.voucherData) return 0;

      if (this.voucherData.amountType === "Amount") {
        return this.voucherData.amount;
      } else if (this.voucherData.amountType === "Percent") {
        var discount =
          this.totalPriceWithoutDiscount * (this.voucherData.amount / 100);
        return discount;
      }

      return 0;
    },
    totalPrice() {
      var totalPrice = this.totalPriceWithoutDiscount;

      if (this.voucherDiscount) {
        totalPrice = totalPrice - this.voucherDiscount;
      }

      return totalPrice.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    totalPriceWithoutDiscount() {
      var totalPriceWithoutDiscount = 0;

      if (this.selectedPlan) {
        totalPriceWithoutDiscount += this.selectedPlan.price;
        if (this.selectedPlan.registrationFee) {
          totalPriceWithoutDiscount += this.selectedPlan.registrationFee;
        }
        if (this.selectedPlan.remittanceFee) {
          totalPriceWithoutDiscount += this.selectedPlan.remittanceFee;
        }
      }

      if (
        this.servicesField &&
        this.formInput.ServiceIds &&
        this.formInput.ServiceIds.length > 0
      ) {
        this.formInput.ServiceIds.forEach((serviceId) => {
          var service = this.servicesField.options.find(
            (el) => el.id === serviceId
          );

          if (service.price) {
            totalPriceWithoutDiscount += service.price;
          }
        });
      }

      return totalPriceWithoutDiscount;
    },
  },
  methods: {
    isHiddenFromConfirmationForm(field) {
      var fieldsHiddenInConfirmationForm = ["Password", "ConfirmPassword"];
      return fieldsHiddenInConfirmationForm.includes(field.key);
    },
    getLabel(field) {
      return field.customLabel ? field.customLabel : field.label;
    },
    getFieldValue(field) {
      var defaultValue = "-";
      var val = _.get(this.formInput, field.key);

      if (val === null || val === undefined) {
        return defaultValue;
      }

      if (field.customFieldId) {
        val = val.value;
      }

      switch (field.type) {
        case "String":
        case "Number":
        case "Email":
          return val;
        case "Date":
          return shortDateFilter(val);
        case "Boolean":
          return this.$t(val ? "yes" : "no");
        case "Plan":
          var selectedPlan = field.options.find((el) => el.id === val);

          return this.getPlanDisplayLabel(selectedPlan);
        case "Dropdown":
        case "ContributionTerms":
        case "Organisation":
          var selectedOption = field.options.find((el) =>
            field.customFieldId ? el.code === val : el.id === val
          );

          return selectedOption ? selectedOption.name : defaultValue;
        case "Discipline":
        case "MultiSelect":
          if (field.customFieldId) {
            var selectedOptionCodes = val.split(", ");
            return field.options
              .filter((el) => selectedOptionCodes.includes(el.code))
              .map((el) => el.name)
              .join(", ");
          }

          return field.options
            .filter((el) => val && val.includes(el.id))
            .map((el) => el.name)
            .join(", ");

        case "Newsletters":
        case "MarketingInterests": {
          let selectedOptions = field.options
            .filter((el) => val && val.includes(el.code))
            .map((el) => el.name)
            .join(", ");

          return selectedOptions;
        }
        case "Country":
          return val ? this.getCountryNameById(val) : defaultValue;
        case "Image":
          return val.fileName ?? defaultValue;
        case "Language": {
          let selectedOption = field.options.find(
            (el) => el.cultureInfoName === val
          );
          return selectedOption ? selectedOption.displayName : defaultValue;
        }
        case "Services": {
          let selectedOptions = field.options
            .filter((el) => val && val.includes(el.id))
            .map((el) => el.name)
            .join(", ");

          return selectedOptions;
        }
      }
    },
    prepareInput() {
      var input = { ...this.formInput };
      if (input.CustomFieldValues) {
        input.CustomFieldValues = input.CustomFieldValues.filter((x) => x);
      }

      if (input.ConfirmPassword) {
        delete input.ConfirmPassword;
      }

      return input;
    },
    submit() {
      this.errorMsg = null;
      this.saving = true;
      axios
        .post(this.registrationFormUrl, this.prepareInput())
        .then((response) => {
          if (this.formConfig.allowDirectPayment && response.data.paymentLink) {
            window.location.href = response.data.paymentLink;
          } else if (this.formConfig.successRedirectUrl) {
            window.location.href = this.formConfig.successRedirectUrl;
          } else {
            this.showSuccess = true;

            window.dispatchEvent(
              new CustomEvent("login", {
                detail: {
                  username: response.data.username,
                  password: this.formInput.Password,
                },
              })
            );
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            this.errorMsg = error.response.data.detail;
          } else {
            this.errorMsg = this.$t("something_went_wrong");
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
  },
};
</script>
