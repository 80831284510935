<template>
  <b-row>
    <b-col cols="12" md="6">
      <base-date-picker
        v-if="membershipStartDateField"
        :label="
          membershipStartDateField.customLabel
            ? membershipStartDateField.customLabel
            : membershipStartDateField.label
        "
        label-cols-sm="4"
        v-model="membershipStartDate"
        :asterix="membershipStartDateField.required"
        :max="maxStartDate"
        :description="membershipStartDateField.description"
      />

      <b-form-group label-cols-sm="4" v-if="planField">
        <template #label>
          {{ planFieldLabel }}
          <span v-if="planField.required">* </span>
          <span v-b-popover.hover.top="planFieldDescription">
            <b-icon-question-circle variant="info" />
          </span>
        </template>
        <validation-provider
          :rules="{ required: planField.required }"
          v-slot="{ errors }"
          :name="planFieldLabel"
        >
          <div class="registration-form-radio">
            <span
              v-if="
                isBirthDateFilled &&
                !membershipStartDateField &&
                availablePlans.length === 0
              "
              >{{ $t("no_plans_found_for_age") }}</span
            >

            <span
              v-if="
                isBirthDateFilled &&
                membershipStartDateField &&
                availablePlans.length === 0
              "
              >{{ $t("no_plans_found_for_age_and_date") }}</span
            >

            <b-form-radio
              v-else
              v-for="option in availablePlans"
              :key="option.id"
              @input="handlePlanSelection"
              v-model="inputValue"
              name="plan-selection"
              :value="option.id"
              class="mb-2"
              :disabled="!isBirthDateFilled"
              >{{ getPlanDisplayLabel(option) }}</b-form-radio
            >

            <p v-for="error in errors" :key="error" class="text-danger mb-0">
              {{ error }}
            </p>
          </div>
        </validation-provider>
      </b-form-group>

      <b-form-group label-cols-sm="4" v-if="organisationField">
        <template #label>
          {{ organisationField.customLabel || organisationField.label }}
          <span v-if="organisationField.required">* </span>
          <span
            v-if="organisationField.description"
            v-b-popover.hover.top="organisationField.description"
          >
            <b-icon-question-circle variant="info" />
          </span>
        </template>
        <validation-provider
          :rules="{ required: organisationField.required }"
          v-slot="{ errors }"
          :name="organisationField.customLabel || organisationField.label"
        >
          <multiselect
            v-model="multiselectValue"
            @input="handleOrganisationIdInput"
            :options="organisationsList"
            :placeholder="$t('select_option')"
            label="name"
            track-by="id"
          />

          <p v-for="error in errors" :key="error" class="text-danger mb-0">
            {{ error }}
          </p>
        </validation-provider>
      </b-form-group>
    </b-col>

    <b-col cols="12" md="6">
      <form-field-input
        v-if="federationMembershipIdentifierField"
        :field="federationMembershipIdentifierField"
        :formConfig="formConfig"
        :input="input"
        @input="handleFieldInput"
      />
      <b-overlay :show="voucherLoading" v-if="voucherField">
        <form-field-input
          v-if="!voucherData"
          :field="voucherField"
          :formConfig="formConfig"
          :input="input"
          @input="handleFieldInput"
        />

        <base-text-row
          v-if="voucherData"
          :label="$t('discount')"
          label-cols-sm="4"
          :value="voucherDiscount"
        />
      </b-overlay>

      <form-field-input
        v-if="disciplineField"
        :field="disciplineField"
        :formConfig="formConfig"
        :input="input"
        @input="handleFieldInput"
      />
    </b-col>
  </b-row>
</template>

<script>
import axios from "axios";
import moduleCommonMixin from "./moduleCommonMixin";
import { appSettings } from "@/appSettings";
import { planMixin } from "@/shared";
import { BIconQuestionCircle } from "bootstrap-vue";
import { extend } from "vee-validate";
import Multiselect from "vue-multiselect";

export default {
  mixins: [moduleCommonMixin, planMixin],
  components: {
    BIconQuestionCircle,
    Multiselect,
  },
  props: {
    formConfig: {
      type: Object,
      required: true,
    },
    formModule: {
      type: Object,
      required: true,
    },
    input: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputValue: null, //not used but required by vee-validate,
      multiselectValue: null, //not used but required by vee-validate,
      voucherLoading: false,
      voucherData: null,
    };
  },
  computed: {
    voucherDiscount() {
      if (!this.voucherData) return 0;

      if (this.voucherData.amountType === "Amount") {
        return `€ ${this.voucherData.amount}`;
      } else if (this.voucherData.amountType === "Percent") {
        return `${this.voucherData.amount}%`;
      }
      return 0;
    },
    voucherValidationUrl() {
      return `${appSettings.FoysPubRegistrationsUrl}/registration-forms/${this.formConfig.id}/validateVoucher/${this.input.VoucherCode}`;
    },
    maxStartDate() {
      var today = new Date();
      return new Date(today.setFullYear(today.getFullYear() + 1));
    },
    availablePlans() {
      var membershipStartDate = this.membershipStartDate
        ? new Date(this.membershipStartDate)
        : new Date();

      var plans = this.planField.options.filter(
        (x) =>
          new Date(x.startDate) <= membershipStartDate &&
          new Date(x.endDate) >= membershipStartDate
      );

      if (!this.isBirthDateFilled) {
        return plans;
      }

      var personAgeToday = this.getPersonAge();

      return plans.filter((plan) => {
        var personAgeToCheck = personAgeToday;

        if (plan.referenceDate) {
          personAgeToCheck = this.getPersonAge(new Date(plan.referenceDate));
        }

        return (
          plan.fromAge <= personAgeToCheck && plan.toAge >= personAgeToCheck
        );
      });
    },
    personBirthDate() {
      return this.input.BirthDate;
    },
    membershipStartDate: {
      get: function () {
        return this.input.PlanAssignment.startDate ?? new Date().toISOString();
      },
      set: function (newValue) {
        this.handleFieldInput({
          value: newValue,
          key: this.membershipStartDateField.key,
        });
      },
    },
    isBirthDateFilled() {
      return !!this.personBirthDate;
    },
    planFieldLabel() {
      return this.planField.customLabel
        ? this.planField.customLabel
        : this.planField.label;
    },
    planFieldDescription() {
      return (
        this.planField.description || this.$t("plan_available_when_birthdate")
      );
    },
    planField() {
      return this.getFormField("PlanAssignment.planId");
    },
    membershipStartDateField() {
      return this.getFormField("PlanAssignment.startDate");
    },
    organisationField() {
      return this.getFormField("PlanAssignment.organisationId");
    },
    federationMembershipIdentifierField() {
      return this.getFormField("FederationMembershipIdentifier");
    },
    disciplineField() {
      return this.getFormField("DisciplineIds");
    },
    voucherField() {
      return this.getFormField("VoucherCode");
    },
    organisationsList() {
      if (!this.organisationField || !this.organisationField.options) return [];

      const selectedPlanId = this.input.PlanAssignment.planId;
      if (!selectedPlanId) return [];

      return this.organisationField.options.filter((organisation) => {
        return (
          this.planField.options.filter(
            (plan) =>
              plan.id === selectedPlanId &&
              (plan.organisationTypeIds.length === 0 ||
                plan.organisationTypeIds.includes(
                  organisation.organisationTypeId
                ))
          ).length > 0
        );
      });
    },
  },
  methods: {
    handleOrganisationIdInput(input) {
      this.handleFieldInput({
        key: this.organisationField.key,
        value: input.id,
      });
    },
    handlePlanSelection(planId) {
      this.handleFieldInput({ value: planId, key: this.planField.key });
    },
    getPersonAge(referenceDate) {
      referenceDate ??= new Date();
      var birthDate = new Date(this.personBirthDate);
      var age = referenceDate.getFullYear() - birthDate.getFullYear();
      var m = referenceDate.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && referenceDate.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async validateVoucher() {
      this.voucherLoading = true;

      await axios
        .get(this.voucherValidationUrl)
        .then((response) => {
          if (response.data) {
            this.voucherData = response.data;
            this.$emit("voucher-loaded", this.voucherData);
            return;
          }
        })
        .catch(() => {
          // console.log(e);
        })
        .finally(() => {
          this.voucherLoading = false;
        });
    },
  },
  created() {
    if (!this.input.PlanAssignment) {
      this.$set(this.input, "PlanAssignment", { planId: null });
    }
    this.$set(this.input, "PlanAssignment", {
      startDate: new Date().toISOString(),
    });

    if (this.voucherField) {
      let me = this;
      extend("voucher", {
        async validate() {
          try {
            await me.validateVoucher();
            return !!(me.input.VoucherCode && me.voucherData);
          } catch (e) {
            return false;
          }
        },
        message: this.$t("invalid_voucher_code"),
      });
    }
  },
};
</script>