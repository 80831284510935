var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('div',{staticClass:"container registration-form"},[_c('link',{attrs:{"rel":"stylesheet","href":"https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css"}}),_c('h1',[_vm._v(_vm._s(_vm.formConfig.title))]),_c('div',{staticClass:"mb-2 mt-2",domProps:{"innerHTML":_vm._s(_vm.formConfig.description)}}),_c('b-alert',{attrs:{"show":!!_vm.errorMsg,"variant":"danger"}},[_vm._v(_vm._s(_vm.errorMsg))]),_c('validation-observer',{directives:[{name:"show",rawName:"v-show",value:(_vm.state === 'Form'),expression:"state === 'Form'"}],scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { return (_vm.state = 'Overview'); })}}},[_vm._l((_vm.formConfig.fieldsConfiguration),function(formModule){return _c('b-row',{key:formModule.name,staticClass:"mt-2"},[_c('b-col',[_c('div',{domProps:{"innerHTML":_vm._s(formModule.description)}}),(formModule.name === 'Address')?_c('address-module',{attrs:{"input":_vm.input,"form-config":_vm.formConfig,"form-module":formModule}}):(formModule.name === 'FinancialDetails')?_c('financial-details-module',{attrs:{"input":_vm.input,"form-config":_vm.formConfig,"form-module":formModule}}):(formModule.name === 'MembershipDetails')?_c('membership-module',{attrs:{"input":_vm.input,"form-config":_vm.formConfig,"form-module":formModule},on:{"voucher-loaded":function (data) {
                _vm.voucherData = data;
              }}}):(formModule.name === 'Services')?_c('services-module',{attrs:{"input":_vm.input,"form-config":_vm.formConfig,"form-module":formModule}}):(!formModule.isCustom)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_vm._l((formModule.fields.filter(
                    function (x, i, a) { return i < Math.ceil(a.length / 2); }
                  )),function(field){return [(field.isVisible)?_c('form-field-input',{key:field.key,attrs:{"field":field,"formConfig":_vm.formConfig,"input":_vm.input},on:{"input":_vm.handleFieldInput}}):_vm._e()]})],2),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_vm._l((formModule.fields.filter(
                    function (x, i, a) { return i >= Math.ceil(a.length / 2); }
                  )),function(field){return [(field.isVisible)?_c('form-field-input',{key:field.key,attrs:{"field":field,"formConfig":_vm.formConfig,"input":_vm.input},on:{"input":_vm.handleFieldInput}}):_vm._e()]})],2)],1)]:(
              formModule.isCustom && formModule.name === 'ParentInfo'
            )?_c('parent-info-module',{model:{value:(_vm.input.ParentInfos),callback:function ($$v) {_vm.$set(_vm.input, "ParentInfos", $$v)},expression:"input.ParentInfos"}}):(formModule.isCustom && formModule.name === 'Files')?_c('files-module',{model:{value:(_vm.input.Files),callback:function ($$v) {_vm.$set(_vm.input, "Files", $$v)},expression:"input.Files"}}):_vm._e()],2)],1)}),_c('b-row',[_c('b-col',{staticClass:"text-center mb-5"},[_c('hr'),_c('b-btn',{staticClass:"continue-button",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.$t("continue")))])],1)],1)],2)]}}],null,false,1232958539)}),_c('b-modal',{attrs:{"id":"personExistsWarningModal","static":"","hide-header":"","no-close-on-esc":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
            var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])]}}],null,false,2077317542)},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.formConfig.textToShowWhenPersonExists || _vm.$t('person_exists'))}})]),(_vm.state === 'Overview')?_c('confirmation-form',{attrs:{"voucherData":_vm.voucherData,"formInput":_vm.input,"formConfig":_vm.formConfig},on:{"cancel":function($event){_vm.state = 'Form'}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }