<template>
  <div class="pb-3">
    <base-date-picker
      v-if="field.type === 'Date'"
      :label="getLabel"
      label-cols-sm="4"
      @input="emitInput"
      v-model="inputValue"
      :asterix="field.required"
      :description="field.description"
      :show-picker="field.key !== 'BirthDate'"
    />
    <b-form-group v-else label-cols-sm="4">
      <template #label>
        {{ getLabel }}
        <span v-if="field.required">*</span>
        <span
          v-if="field.description"
          v-b-popover.hover.top="field.description"
        >
          <b-icon-question-circle variant="info" />
        </span>
      </template>
      <validation-provider
        :rules="fieldValidationRules"
        v-slot="{ errors }"
        :name="getLabel"
        :mode="validationMode"
        :vid="field.key"
      >
        <b-form-input
          v-if="field.type === 'String' || field.type === 'Email'"
          v-model="inputValue"
          @input="emitInput"
          trim
        />

        <b-input-group v-if="field.type === 'Password'">
          <b-form-input
            v-model="inputValue"
            @input="emitInput"
            trim
            :type="passwordFieldInputType"
            autocomplete="new-password"
          />
          <b-input-group-append>
            <b-button
              variant="outline-secondary"
              @click="togglePasswordVisibility"
            >
              <b-icon-eye-fill />
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <b-form-input
          v-if="field.type === 'Number'"
          v-model="inputValue"
          @input="emitInput"
          trim
          type="number"
        />

        <b-form-checkbox
          v-model="inputValue"
          v-if="field.type === 'Boolean'"
          @input="emitInput"
        />

        <b-form-select
          v-if="isDropdownFieldType && !field.customFieldId"
          v-model="inputValue"
          @input="emitInput"
          :options="getOptions"
          value-field="id"
          text-field="name"
        />

        <b-form-select
          v-if="isDropdownFieldType && field.customFieldId"
          v-model="inputValue"
          @input="emitInput"
          :options="getOptions"
          value-field="code"
          text-field="name"
        />

        <multiselect
          v-if="isMultiselectFieldType && field.customFieldId"
          :multiple="true"
          v-model="multiSelectValue"
          :options="getOptions"
          :placeholder="$t('select_option')"
          label="name"
          track-by="code"
        />

        <multiselect
          v-if="isMultiselectFieldType && !field.customFieldId"
          :multiple="true"
          v-model="multiSelectValue"
          :options="getOptions"
          :placeholder="$t('select_option')"
          label="name"
          track-by="id"
        />

        <b-form-select
          v-model="countrySelection"
          v-if="field.type === 'Country'"
          :options="countriesList"
          value-field="id"
          text-field="name"
        />

        <image-input
          v-if="field.type === 'Image'"
          v-model="inputValue"
          @input="emitInput"
        />

        <b-form-select
          v-if="field.type === 'Language'"
          v-model="inputValue"
          @input="emitInput"
          :options="getOptions"
          value-field="cultureInfoName"
          text-field="displayName"
        />

        <multiselect
          v-if="field.type === 'Organisation'"
          v-model="inputValue"
          @input="emitInput"
          :options="getOptions"
          :placeholder="$t('select_option')"
          label="name"
          track-by="id"
        />

        <multiselect
          v-if="
            field.type === 'Newsletters' || field.type === 'MarketingInterests'
          "
          v-model="multiSelectValue"
          :options="getOptions"
          :placeholder="$t('select_option')"
          label="name"
          track-by="code"
          :multiple="true"
        />

        <p v-for="error in errors" :key="error" class="text-danger mb-0">
          {{ error }}
        </p>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { countryHelper } from "../countryHelper.js";
import { BIconQuestionCircle, BIconEyeFill } from "bootstrap-vue";
import { genderEnum } from "@/shared";
import _ from "lodash";
import Multiselect from "vue-multiselect";

export default {
  mixins: [countryHelper, genderEnum],
  components: {
    BIconQuestionCircle,
    BIconEyeFill,
    Multiselect,
  },
  name: "FormField",
  data() {
    return {
      inputValue: null, //not used but required by vee-validate
      passwordFieldInputType: "password",
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    formConfig: {
      type: Object,
      required: true,
    },
    input: {
      type: Object,
      default: () => ({}),
    },
    value: null,
  },
  methods: {
    async emitInput(val) {
      if (this.field.type === "Organisation") {
        val = val.id;
      }

      this.$emit("input", {
        key: this.field.key,
        value: val,
        customFieldId: this.field.customFieldId,
      });
    },
    validationMode({ errors }) {
      if (this.field.key === "VoucherCode") {
        return {
          on: ["blur", "input", "change"],
          debounce: 1000,
        };
      }
      if (errors.length) {
        return {
          on: ["input", "change"],
        };
      }

      return {
        on: ["change", "blur"],
      };
    },
    togglePasswordVisibility() {
      if (this.passwordFieldInputType === "password") {
        this.passwordFieldInputType = "text";
      } else {
        this.passwordFieldInputType = "password";
      }
    },
  },
  computed: {
    countrySelection: {
      get: function () {
        const existingValue = _.get(this.input, this.field.key);

        if (!existingValue) this.emitInput(this.defaultCountry.id);

        return existingValue || this.defaultCountry.id;
      },
      set: function (newValue) {
        this.emitInput(newValue);
      },
    },
    multiSelectValue: {
      get: function () {
        return this.inputValue;
      },
      set: function (newValue) {
        this.inputValue = newValue;

        if (!Array.isArray(newValue)) {
          this.emitInput(null);
        }

        var parsedValue = this.field.customFieldId
          ? newValue.map((el) => el.code).join(", ")
          : newValue.map((el) => (el.id ? el.id : el.code));

        this.emitInput(parsedValue);
      },
    },

    defaultCountry() {
      return this.countries.find(
        (el) => el.alpha2 === this.formConfig.defaultCountryCode
      );
    },
    countriesList() {
      var countriesList = this.countries.filter(
        (el) => el.alpha2 !== this.formConfig.defaultCountryCode
      );

      countriesList.unshift(this.defaultCountry);

      return countriesList;
    },
    getOptions() {
      if (this.field.key === "Gender") {
        return this.genderTypeList;
      }
      if (this.field.type === "Organisation") {
        return this.field.options.map((el) => {
          return {
            id: el.id,
            name: el.code ? `${el.name} (${el.code})` : el.name,
          };
        });
      }
      return this.field.options;
    },
    getLabel() {
      return this.field.customLabel ? this.field.customLabel : this.field.label;
    },
    isDropdownFieldType() {
      return ["Plan", "ContributionTerms", "Dropdown"].includes(
        this.field.type
      );
    },
    isMultiselectFieldType() {
      return ["Discipline", "MultiSelect"].includes(this.field.type);
    },
    fieldValidationRules() {
      let str = this.field.required ? "required|" : "";

      if (this.field.key === "Password") {
        str += "min:8|upcase|lowcase|requireDigit|requireNonAlphaNum";
      } else if (this.field.key === "ConfirmPassword") {
        str += "confirmPassword";
      } else if (this.field.key === "BillingInfoDto.iban") {
        str += "ibanLength|iban";
      } else if (this.field.type === "Email") {
        str += "email";
      } else if (this.field.key === "VoucherCode") {
        str += "voucher";
      } else if (this.field.key === "AddressInfo.houseNumberExtension") {
        str += "max:50";
      } else if (this.field.key === "AddressInfo.houseNumber") {
        str += "max:50";
      }

      return str;
    },
  },
  watch: {
    value(val) {
      this.inputValue = val;
    },
  },
};
</script>